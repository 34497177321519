import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStore } from '../../providers/store-provider';
import { ARROW_NAV_CONTAINER_CLASS, ARROW_NAV_CONTAIN_ATTRIBUTE } from '@raybrowser/nav-system';
import styles from './modal.module.css';

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  className?: string;
};

export function Modal({ className = '', children, onClose, open }: ModalProps) {
  const setModalOpen = useStore(({ setModalOpen }) => setModalOpen);
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  // Get portal root.
  useEffect(() => {
    setPortalRoot(document.getElementById('modals'));
  }, [setPortalRoot]);

  // Sync global store modal open state.
  useEffect(() => {
    setModalOpen(open);
  }, [open, setModalOpen]);

  // Close modal on esc.
  useEffect(() => {
    if (!open) return;
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose();
      }
    }
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose, open]);

  if (!portalRoot) return null;

  return createPortal(
    <div
      className={`${styles.root} ${
        open ? styles.isOpen : ''
      }  ${className} ${ARROW_NAV_CONTAINER_CLASS}`}
      {...{
        [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x y',
      }}
    >
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.content}>{children}</div>
    </div>,
    portalRoot,
  );
}
