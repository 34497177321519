import { useEffect, useMemo, useCallback, RefObject } from 'react';
import { useRefState } from './use-ref-state';
import { debounce } from '../utils/debounce';
import { getWidth } from '../utils/get-width';
import { getMaxScrollLeft } from '../utils/get-max-scroll-left';
import { ticker } from '../utils/ticker';

export function useCardScroller(
  scrollerRef: RefObject<HTMLElement>,
  scrollContentRef: RefObject<HTMLElement>,
  scrollIndicatorThumbRef: RefObject<HTMLElement>,
) {
  const [canScrollForward, setCanScrollForward, getCanScrollForward] = useRefState(false);

  const [canScrollBack, setCanScrollBack, getCanScrollBack] = useRefState(false);

  const canScroll = canScrollBack || canScrollForward;

  const scrollToNextBatch = useCallback(() => {
    const scrollElem = scrollerRef.current;
    if (!scrollElem) return;

    const scrollLeft = scrollElem.scrollLeft;
    const maxScrollLeft = getMaxScrollLeft(scrollElem);

    // If the element is scrolled to the end already, do nothing.
    if (Math.ceil(scrollLeft) === Math.floor(maxScrollLeft)) {
      return;
    }

    // Compute the next scrollLeft value.
    const nextScrollLeft = Math.min(scrollLeft + getWidth(scrollElem, 'content'), maxScrollLeft);

    scrollElem.setAttribute('data-batch-scrolling', 'true');

    // Scroll to the next batch smoothly.
    scrollElem.scroll({
      left: nextScrollLeft,
      behavior: 'smooth',
    });

    scrollElem.addEventListener(
      'scrollend',
      () => {
        if (scrollElem.isConnected) {
          scrollElem.removeAttribute('data-batch-scrolling');
        }
      },
      { once: true },
    );
  }, [scrollerRef]);

  const scrollToPrevBatch = useCallback(() => {
    const scrollElem = scrollerRef.current;
    if (!scrollElem) return;

    const scrollLeft = scrollElem.scrollLeft;

    // If the element is scrolled to the beginning already, do nothing.
    if (scrollLeft === 0) {
      return;
    }

    // Compute the next scrollLeft value.
    const nextScrollLeft = Math.max(scrollLeft - getWidth(scrollElem, 'content'), 0);

    // Set the batch scrolling attribute.
    scrollElem.setAttribute('data-batch-scrolling', 'true');

    // Scroll to the prev batch smoothly.
    scrollElem.scroll({
      left: nextScrollLeft,
      behavior: 'smooth',
    });

    scrollElem.addEventListener(
      'scrollend',
      () => {
        if (scrollElem.isConnected) {
          scrollElem.removeAttribute('data-batch-scrolling');
        }
      },
      { once: true },
    );
  }, [scrollerRef]);

  // Check if the scroller can be scrolled back/forward.
  useEffect(() => {
    const scrollElem = scrollerRef.current;
    const scrollContentElem = scrollContentRef.current;
    if (!scrollElem || !scrollContentElem) return;

    const checkScrollState = () => {
      // If element can be scrolled back.
      if (scrollElem.scrollLeft > 0) {
        !getCanScrollBack() && setCanScrollBack(true);
      }
      // If element cannot be scrolled back.
      else {
        getCanScrollBack() && setCanScrollBack(false);
      }

      // If element can be scrolled forward.
      if (
        Math.ceil(scrollElem.scrollLeft) <
        Math.floor(scrollElem.scrollWidth - getWidth(scrollElem, 'padding'))
      ) {
        !getCanScrollForward() && setCanScrollForward(true);
      }
      // If element cannot be scrolled forward.
      else {
        getCanScrollForward() && setCanScrollForward(false);
      }
    };

    const debouncedCheckScrollState = debounce(checkScrollState, 100);

    // Initial check.
    checkScrollState();

    // Check after each scroll.
    scrollElem.addEventListener('scroll', debouncedCheckScrollState);

    // Check after each relevant resize.
    const resizeObserver = new ResizeObserver(debouncedCheckScrollState);
    resizeObserver.observe(scrollElem);
    resizeObserver.observe(scrollContentElem);

    return () => {
      scrollElem.removeEventListener('scroll', debouncedCheckScrollState);
      resizeObserver.disconnect();
    };
  }, [
    scrollerRef,
    scrollContentRef,
    getCanScrollBack,
    getCanScrollForward,
    setCanScrollBack,
    setCanScrollForward,
  ]);

  // Sync scroll indicator thumb size and position.
  useEffect(() => {
    const scrollElem = scrollerRef.current;
    const scrollContentElem = scrollContentRef.current;
    const scrollThumbElem = scrollIndicatorThumbRef.current;
    if (!scrollElem || !scrollContentElem || !scrollThumbElem) return;

    let thumbWidth = 0;
    let prevThumbWidth = 0;
    let thumbWidthCallbackId = Symbol();
    const updateThumbWidth = () => {
      ticker.once(
        'read',
        () => {
          thumbWidth = scrollElem.clientWidth / scrollElem.scrollWidth || 0;
        },
        thumbWidthCallbackId,
      );

      ticker.once(
        'write',
        () => {
          if (thumbWidth !== prevThumbWidth) {
            prevThumbWidth = thumbWidth;
            scrollThumbElem.style.setProperty('--card-scroller--thumb-width', `${thumbWidth}`);
          }
        },
        thumbWidthCallbackId,
      );
    };

    let thumbPosition = 0;
    let prevThumbPosition = 0;
    let thumbPositionCallbackId = Symbol();
    const updateThumbPosition = () => {
      ticker.once(
        'read',
        () => {
          thumbPosition =
            Math.ceil(scrollElem.scrollLeft) /
              Math.floor(scrollElem.scrollWidth - scrollElem.clientWidth) || 0;
        },
        thumbPositionCallbackId,
      );
      ticker.once(
        'write',
        () => {
          if (thumbPosition !== prevThumbPosition) {
            prevThumbPosition = thumbPosition;
            scrollThumbElem.style.setProperty(
              '--card-scroller--thumb-position',
              `${thumbPosition}`,
            );
          }
        },
        thumbPositionCallbackId,
      );
    };

    // Apply initial sizing and position.
    updateThumbWidth();
    updateThumbPosition();

    // Update thumb after each relevant resize.
    const resizeObserver = new ResizeObserver(updateThumbWidth);
    resizeObserver.observe(scrollElem);
    resizeObserver.observe(scrollContentElem);

    // Update thumb after each scroll.
    scrollElem.addEventListener('scroll', updateThumbPosition);

    return () => {
      ticker.off('read', thumbWidthCallbackId);
      ticker.off('write', thumbWidthCallbackId);
      ticker.off('read', thumbPositionCallbackId);
      ticker.off('write', thumbPositionCallbackId);
      resizeObserver.disconnect();
      scrollElem.removeEventListener('scroll', updateThumbPosition);
      scrollThumbElem.style.removeProperty('--card-scroller--thumb-width');
      scrollThumbElem.style.removeProperty('--card-scroller--thumb-position');
    };
  }, [scrollerRef, scrollContentRef, scrollIndicatorThumbRef]);

  // Scroll on mouse swipe.
  useEffect(() => {
    const scrollElem = scrollerRef.current;
    if (!scrollElem || !canScroll) return;

    let attributeRemoveTimeout: number | undefined = undefined;
    let pointerId: number | null = null;
    let isSwipingX = false;
    let startTime = 0;
    let startX = 0;
    let startY = 0;

    const clearAttributeRemoveTimeout = () => {
      if (attributeRemoveTimeout !== undefined) {
        window.clearTimeout(attributeRemoveTimeout);
        attributeRemoveTimeout = undefined;
        scrollElem.removeAttribute('data-mouse-swipe');
      }
    };

    const resetDrag = () => {
      clearAttributeRemoveTimeout();

      window.removeEventListener('pointermove', onDragMove);
      window.removeEventListener('pointercancel', onDragEnd);
      window.removeEventListener('pointerup', onDragEnd);

      pointerId = null;
      isSwipingX = false;
      startTime = startX = startY = 0;
    };

    const onDragStart = (e: PointerEvent) => {
      clearAttributeRemoveTimeout();

      if (pointerId === null && e.pointerType === 'mouse' && e.button === 0) {
        pointerId = e.pointerId;
        startTime = e.timeStamp;
        startX = e.clientX;
        startY = e.clientY;

        window.addEventListener('pointermove', onDragMove, { passive: true });
        window.addEventListener('pointercancel', onDragEnd, { passive: true });
        window.addEventListener('pointerup', onDragEnd, { passive: true });
      }
    };

    const onDragMove = (e: PointerEvent) => {
      if (e.pointerId === pointerId) {
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        // Try to initiate swipe gesture.
        if (
          // Make sure swipe gesture has not yet been initiated.
          !isSwipingX &&
          // Make sure we have start time available.
          startTime &&
          // Make sure deltaX exceeds threshold.
          Math.abs(deltaX) > 20 &&
          // Make sure deltaX is larger than deltaY.
          Math.abs(deltaX) > Math.abs(deltaY) &&
          // Make sure the gesture does not exceed the time threshold.
          e.timeStamp - startTime < 300
        ) {
          isSwipingX = true;
          scrollElem.setAttribute('data-mouse-swipe', 'true');
          if (deltaX < 0) {
            scrollToNextBatch();
          } else {
            scrollToPrevBatch();
          }
        }
      }
    };

    const onDragEnd = (e: PointerEvent) => {
      if (e.pointerId === pointerId) {
        resetDrag();
        attributeRemoveTimeout = window.setTimeout(() => {
          attributeRemoveTimeout = undefined;
          scrollElem.removeAttribute('data-mouse-swipe');
        }, 50);
      }
    };

    // Listen to start event.
    scrollElem.addEventListener('pointerdown', onDragStart, { passive: true });

    return () => {
      resetDrag();
      scrollElem.removeEventListener('pointerdown', onDragStart);
    };
  }, [scrollerRef, canScroll, scrollToNextBatch, scrollToPrevBatch]);

  return useMemo(() => {
    return {
      scrollToPrevBatch,
      scrollToNextBatch,
      canScrollBack,
      canScrollForward,
      canScroll,
    };
  }, [scrollToPrevBatch, scrollToNextBatch, canScroll, canScrollBack, canScrollForward]);
}
