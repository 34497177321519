import { useEffect, useState } from 'react';
import styles from './loading-spinner.module.css';

export type LoadingSpinnerProps = {
  active?: boolean;
};

export function LoadingSpinner({ active = true }: LoadingSpinnerProps) {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    let rafId = window.requestAnimationFrame(() => {
      rafId = window.requestAnimationFrame(() => {
        setReady(true);
      });
    });
    return () => {
      window.cancelAnimationFrame(rafId);
    };
  }, [setReady]);

  return <div className={`${styles.root} ${isReady && active ? styles.isActive : ''}`}></div>;
}
