import { SvgIcon, SvgIconProps } from './svg-icon';

export function RayLogoIcon({ viewBox = '0 0 108 40', ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} {...restProps}>
      <g fill="currentColor">
        <path d="M32 0H12C8.81849 0.00352914 5.7683 1.26895 3.51862 3.51862C1.26895 5.76829 0.00352914 8.81849 0 12V40H8V12C8 10.9391 8.42143 9.92172 9.17158 9.17158C9.92173 8.42143 10.9391 8 12 8H32V0Z" />
        <path d="M95.9999 24V16H83.9999C82.939 16 81.9216 15.5786 81.1715 14.8284C80.4213 14.0783 79.9999 13.0609 79.9999 12V0H71.9999V12C72.0034 15.1815 73.2688 18.2317 75.5185 20.4814C77.7682 22.731 80.8184 23.9965 83.9999 24H95.9999Z" />
        <path d="M55.9999 0H35.9999V8H55.9999C57.0608 8 58.0782 8.42143 58.8283 9.17158C59.5785 9.92172 59.9999 10.9391 59.9999 12V28C59.9999 29.0609 59.5785 30.0783 58.8283 30.8284C58.0782 31.5786 57.0608 32 55.9999 32H39.9999C38.939 32 37.9216 31.5786 37.1715 30.8284C36.4213 30.0783 35.9999 29.0609 35.9999 28C35.9999 26.9391 36.4213 25.9217 37.1715 25.1716C37.9216 24.4214 38.939 24 39.9999 24H55.9999V16H39.9999C36.8184 16.0035 33.7682 17.269 31.5185 19.5186C29.2688 21.7683 28.0034 24.8185 27.9999 28C28.0034 31.1815 29.2688 34.2317 31.5185 36.4814C33.7682 38.7311 36.8184 39.9965 39.9999 40H55.9999C59.1814 39.9965 62.2316 38.7311 64.4813 36.4814C66.7309 34.2317 67.9964 31.1815 67.9999 28V12C67.9964 8.81849 66.7309 5.76829 64.4813 3.51862C62.2316 1.26895 59.1814 0.00352914 55.9999 0Z" />
        <path d="M100 0V28C100 29.0609 99.5786 30.0783 98.8284 30.8284C98.0783 31.5786 97.0609 32 96 32H76V40H96C99.1815 39.9965 102.232 38.7311 104.481 36.4814C106.731 34.2317 107.996 31.1815 108 28V0H100Z" />
      </g>
    </SvgIcon>
  );
}
