const DEFAULT_DURATION = 50;

let timeout: number | undefined = undefined;

export const clearCardDeactivationTimeout = () => {
  window.clearTimeout(timeout);
  timeout = undefined;
};

export const setCardDeactivationTimeout = (callback: () => void, duration = DEFAULT_DURATION) => {
  clearCardDeactivationTimeout();
  timeout = window.setTimeout(callback, duration);
};
