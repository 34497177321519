import { SvgIcon, SvgIconProps } from './svg-icon';

export function CloseIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} {...restProps}>
      <path
        d="M28 7.93L26.41 6L16 14.4L5.59 6L4 7.93L14.01 16L4 24.07L5.59 26L16 17.6L26.41 26L28 24.07L17.99 16L28 7.93Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
