import { useEffect } from 'react';

export function useRootClass(className: string, isActive: boolean) {
  useEffect(() => {
    const { classList } = document.documentElement;
    if (isActive) {
      classList.add(className);
    } else {
      classList.remove(className);
    }

    return () => {
      classList.remove(className);
    };
  }, [className, isActive]);
}
