import { graphql } from './.gql';

export const GetIndexPage = graphql(`
  query GetIndexPage {
    featuredApps(
      where: { app: { status: { equals: published } } }
      orderBy: { index: asc }
      take: 1
    ) {
      app {
        id
        name
        shortDescription
        launchUrl
        tags {
          id
          name
        }
        logoImage {
          url
        }
        previewImage {
          url
        }
        bgImage {
          url
        }
        previewVideo {
          url
        }
        bgVideo {
          url
        }
      }
    }
    featuredTags(orderBy: { index: asc }) {
      tag {
        id
        name
        featuredApps(where: { app: { status: { equals: published } } }, orderBy: { index: asc }) {
          app {
            id
            name
            shortDescription
            launchUrl
            logoImage {
              url
            }
            previewImage {
              url
            }
            previewVideo {
              url
            }
            tags {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export const GetAppModal = graphql(`
  query GetAppModal($appId: ID!) {
    app(where: { id: $appId }) {
      id
      name
      description
      launchUrl
      tags {
        id
        name
      }
      previewImage {
        url
      }
      previewVideo {
        url
      }
    }
  }
`);

export const GetRecentlyPlayed = graphql(`
  query GetRecentlyPlayed($appIds: [ID!]) {
    apps(where: { AND: [{ id: { in: $appIds } }, { status: { equals: published } }] }) {
      id
      name
      shortDescription
      launchUrl
      tags {
        id
        name
      }
      logoImage {
        url
      }
      previewImage {
        url
      }
      bgImage {
        url
      }
      bgVideo {
        url
      }
    }
  }
`);
