import { ComponentProps } from 'react';

export interface SvgIconProps extends Omit<ComponentProps<'svg'>, 'ref'> {}

export function SvgIcon({
  children,
  xmlns = 'http://www.w3.org/2000/svg',
  viewBox = '0 0 512 512',
  'aria-hidden': ariaHidden = 'true',
  ...restProps
}: SvgIconProps) {
  return (
    <svg xmlns={xmlns} viewBox={viewBox} aria-hidden={ariaHidden} {...restProps}>
      {children}
    </svg>
  );
}
