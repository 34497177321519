import { useCallback, useMemo, PointerEvent, FocusEvent } from 'react';
import Link from 'next/link';
import { useApolloClient } from '@apollo/client';
import { GetAppModal } from '../../graphql-queries';
import { AnchorButton, AnchorButtonProps } from '../buttons/button/button';

export type AppDetailLinkProps = AnchorButtonProps & {
  prefetch?: boolean;
};

export function AppDetailLink({
  href = '',
  prefetch = true,
  children,
  onFocus,
  onPointerEnter,
  ...restProps
}: AppDetailLinkProps) {
  const apolloClient = useApolloClient();
  const appId = useMemo(() => href.replace('?app=', ''), [href]);

  const prefetchData = useCallback(() => {
    if (!prefetch) return;
    apolloClient
      .query({
        query: GetAppModal,
        variables: { appId },
      })
      .catch((e) => {
        // TODO: Handle error properly (e.g. send to Sentry).
        console.error(`Failed to prefetch modal data for app: ${appId}`);
        console.error(e);
      });
  }, [prefetch, apolloClient, appId]);

  const handlePointerEnter = useCallback(
    (e: PointerEvent<HTMLAnchorElement>) => {
      prefetchData();
      onPointerEnter?.(e);
    },
    [prefetchData, onPointerEnter],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLAnchorElement, Element>) => {
      prefetchData();
      onFocus?.(e);
    },
    [prefetchData, onFocus],
  );

  return (
    <Link href={href} scroll={false} prefetch={false} shallow passHref legacyBehavior>
      <AnchorButton onPointerEnter={handlePointerEnter} onFocus={handleFocus} {...restProps}>
        {children}
      </AnchorButton>
    </Link>
  );
}
