import { memo, useRef } from 'react';
import { AnchorButton } from '../buttons/button/button';
import { AppDetailLink } from '../app-detail-link/app-detail-link';
import { Tag } from '../tag/tag';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { useStore } from '../../providers/store-provider';
import { navSystem } from '@raybrowser/nav-system';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import styles from './app-card-popover.module.css';

export type AppCardPopoverProps = {
  // App data
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  previewVideoUrl?: string;
  shortDescription?: string;
  tags?: { id: string; name: string }[];
  isUnityGame?: boolean;
  // Other props
  isCardActive: boolean;
  isVisible: boolean;
};

export const AppCardPopover = ({ isCardActive, isVisible, ...app }: AppCardPopoverProps) => {
  const launchActionRef = useRef<HTMLAnchorElement | null>(null);
  const addRecentlyPlayed = useStore(({ addRecentlyPlayed }) => addRecentlyPlayed);

  // Focus the launch action when the popover is opened.
  useIsomorphicLayoutEffect(() => {
    if (
      isCardActive &&
      launchActionRef.current &&
      launchActionRef.current !== document.activeElement &&
      navSystem.getNavDevice() === 'keyboard'
    ) {
      launchActionRef.current.focus({ preventScroll: true });
    }
  }, [isCardActive]);

  // A quick note about the isCardActive and isVisible props. isVisible is set
  // to true a tick later than isCardActive which is important to make the
  // show transitions work. On first render there is no `styles.isVisible`
  // class in the root element so the element's will render in the hidden state.
  // Then the `styles.isVisible` class is added in the next tick which triggers
  // the show transition.

  return isCardActive || isVisible ? (
    <div className={`${styles.root} ${isCardActive && isVisible ? styles.isVisible : ''}`}>
      <div className={styles.container}>
        <div className={styles.bg}></div>
        <div className={styles.content}>
          <div className={styles.title}>{app.name}</div>
          {!!app.shortDescription && (
            <div className={styles.description}>{app.shortDescription}</div>
          )}
          {!!app.tags?.length && (
            <div className={styles.tags}>
              {app.tags.map((tag) => (
                <Tag key={tag.id} name={tag.name} />
              ))}
            </div>
          )}
          <div className={styles.actions}>
            <AnchorButton
              ref={launchActionRef}
              className={styles.launchAction}
              theme="Primary"
              href={app.launchUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => !app.isUnityGame && addRecentlyPlayed(app.id)}
            >
              Play
            </AnchorButton>
            <AppDetailLink
              className={styles.infoAction}
              theme="SecondaryLight"
              href={`?app=${app.id}`}
              prefetch={!app.isUnityGame}
            >
              <ChevronDownIcon />
            </AppDetailLink>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const AppCardPopoverMemo = memo(AppCardPopover);
