import { memo, useCallback, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import { AnchorButton } from '../buttons/button/button';
import { AppDetailLink } from '../app-detail-link/app-detail-link';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { useSymbol } from '../../hooks/use-symbol';
import { useStore } from '../../providers/store-provider';
import {
  setCardDeactivationTimeout,
  clearCardDeactivationTimeout,
} from '../../utils/card-deactivation-timeout';
import {
  navSystem,
  ARROW_NAV_TARGET_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '@raybrowser/nav-system';
import styles from './slider-card.module.css';

export type AppCardProps = {
  // App data
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  // Other props
  slideIndex: number;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

export function SliderCard(props: AppCardProps) {
  const cardId = useSymbol();
  const isActive = useStore(({ activeCardId }) => activeCardId === cardId);
  const addRecentlyPlayed = useStore(({ addRecentlyPlayed }) => addRecentlyPlayed);
  const setActiveCardId = useStore(({ setActiveCardId }) => setActiveCardId);

  // Keep props up to date in the propsRef.
  const propsRef = useRef(props);
  propsRef.current = props;

  const activateCard = useCallback(() => {
    clearCardDeactivationTimeout();
    if (!isActive) {
      setActiveCardId(cardId);
    }
  }, [cardId, isActive, setActiveCardId]);

  const deactivateCard = useCallback(() => {
    setCardDeactivationTimeout(() => {
      setActiveCardId(null);
    });
  }, [setActiveCardId]);

  const onPointerEnter = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      activateCard();
    }
  }, [activateCard]);

  const onPointerLeave = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      deactivateCard();
    }
  }, [deactivateCard]);

  const onFocus = useCallback(() => {
    activateCard();
  }, [activateCard]);

  const onBlur = useCallback(() => {
    deactivateCard();
  }, [deactivateCard]);

  // Handle active slide index change based on the isActive state. Note that we
  // want to run this effect only when the isActive state changes, which is why
  // we're not including isActive in setActiveSlideIndex and slideIndex deps in
  // the deps array, and instead get them from the propsRef.
  useEffect(() => {
    const { setActiveSlideIndex, slideIndex } = propsRef.current;

    if (isActive) {
      setActiveSlideIndex(slideIndex);
    } else {
      // Let's reset the active slide index to 0 when the card is deactivated IF
      // the active slide index is still the same as the slide index of this card.
      // Note that we have to be really careful here as the onActivate is often
      // called right before onDeactivate (in the same tick) so we have to do the
      // check in the state callback to ensure we're not resetting the active
      // slide index when it's already been updated.
      setActiveSlideIndex((activeSlideIndex) => {
        if (activeSlideIndex === slideIndex) return 0;
        return activeSlideIndex;
      });
    }
  }, [isActive]);

  return (
    <div
      className={styles.root}
      onPointerDown={onPointerEnter}
      onPointerUp={onPointerEnter}
      onPointerMove={onPointerEnter}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'x',
      }}
    >
      <div
        className={styles.interactionArea}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'x',
        }}
      ></div>
      <div className={styles.imageContainer}>
        <Image
          src={props.previewImageUrl}
          alt={props.name}
          draggable="false"
          fill
          quality={85}
          sizes={`(max-width: 640px) 50vw,(max-width: 1280px) 33vw,(max-width: 1920px) 25vw,(max-width: 2560px) 20vw,(max-width: 3200px) 16vw,(max-width: 3840px) 14vw`}
        />
        <a
          className={styles.imageLink}
          href={props.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => addRecentlyPlayed(props.id)}
          tabIndex={-1}
          draggable={false}
        ></a>
      </div>
      <div className={styles.title}>{props.name}</div>
      <div className={styles.titleActive}>{props.name}</div>
      <div className={styles.actions}>
        <AnchorButton
          className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
          theme="Primary"
          href={props.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => addRecentlyPlayed(props.id)}
        >
          Play
        </AnchorButton>
        <AppDetailLink
          className={styles.infoAction}
          theme="SecondaryLight"
          href={`?app=${props.id}`}
        >
          <ChevronDownIcon />
        </AppDetailLink>
      </div>
    </div>
  );
}

export const SliderCardMemo = memo(SliderCard);
