import { SvgIcon, SvgIconProps } from './svg-icon';

export function ChevronRightIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} {...restProps}>
      <path
        d="M12.3312 27L10.5032 25.22L19.2015 16.75C19.4891 16.47 19.4891 16.02 19.2015 15.74L10 6.78L11.828 5L21.0295 13.96C22.3235 15.22 22.3235 17.27 21.0295 18.53L12.3312 27Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
