import React, { useRef, useCallback, forwardRef, ComponentProps, KeyboardEvent } from 'react';
import { getScrollableAncestors } from '../../../utils/get-scrollable-ancestors';
import styles from './base-button.module.css';

export interface BaseButtonProps extends Omit<ComponentProps<'button'>, 'ref'> {
  spaceAction?: 'click' | 'scroll' | 'default';
}

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  function BaseButtonComponent(
    {
      spaceAction = 'scroll',
      type = 'button',
      className = '',
      children = null,
      onKeyDown = null,
      onKeyUp = null,
      ...props
    },
    ref,
  ) {
    const internalRef = useRef<HTMLButtonElement | null>(null);

    const refCallback = useCallback(
      (node: HTMLButtonElement | null) => {
        internalRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      },
      [ref],
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLButtonElement>) => {
        // Trigger click on space keydown if space action is "click". By default
        // button will click/submit on space keyup, but we want to have
        // identical behavior for both space and enter keys in this case for
        // better UX.
        if (e.key == ' ' && spaceAction == 'click') {
          e.preventDefault();
          onKeyDown?.(e);
          e.currentTarget?.click();
          return;
        }

        // Scroll on space keydown if space action is "scroll".
        if (e.key == ' ' && spaceAction == 'scroll') {
          e.preventDefault();
          // Chrome's scroll delta seems to be window.innerHeight - 20 while
          // Firefox and Safari use window.innerHeight * 0.8.
          const delta = window.innerHeight - 20;

          // Scroll the closest scrollable element.
          const scrollParent = getScrollableAncestors(internalRef.current, {
            axis: 'y',
            firstMatchOnly: true,
            includeWindow: false,
          })[0];

          onKeyDown?.(e);

          scrollParent?.scrollBy({
            top: e.shiftKey ? -delta : delta,
            behavior: 'instant',
          });

          return;
        }

        onKeyDown?.(e);
      },
      [spaceAction, onKeyDown],
    );

    const handleKeyUp = useCallback(
      (e: KeyboardEvent<HTMLButtonElement>) => {
        // Prevent default behavior for space key if a custom space action
        // is defined.
        if (spaceAction !== 'default') e.preventDefault();
        onKeyUp?.(e);
      },
      [spaceAction, onKeyUp],
    );

    return (
      <button
        ref={refCallback}
        type={type}
        className={`${styles.root} ${className}`}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export interface BaseAnchorButtonProps extends Omit<ComponentProps<'a'>, 'ref'> {
  spaceAction?: 'click' | 'scroll' | 'default';
}

export const BaseAnchorButton = forwardRef<HTMLAnchorElement, BaseAnchorButtonProps>(
  function BaseAnchorButtonComponent(
    {
      spaceAction = 'scroll',
      className = '',
      draggable = false,
      children = null,
      onKeyDown = null,
      ...props
    },
    ref,
  ) {
    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLAnchorElement>) => {
        // Trigger click on space keydown if space action is "click". By default
        // anchor will scroll the page here.
        if (e.key == ' ' && spaceAction == 'click') {
          e.preventDefault();
          onKeyDown?.(e);
          e.currentTarget?.click();
          return;
        }

        onKeyDown?.(e);
      },
      [onKeyDown, spaceAction],
    );

    return (
      <a
        ref={ref}
        className={`${styles.root} ${className}`}
        draggable={draggable}
        onKeyDown={handleKeyDown}
        {...props}
      >
        {children}
      </a>
    );
  },
);
