import { SvgIcon, SvgIconProps } from './svg-icon';

export function PlayIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} {...restProps}>
      <path
        d="M10.27 27.5C9.62 27.5 8.98 27.33 8.39 26.99C7.21 26.31 6.5 25.1 6.5 23.74V8.26C6.5 6.9 7.21 5.69 8.39 5.01C9.57 4.33 10.98 4.33 12.16 5.01L25.62 12.75C26.8 13.43 27.5 14.64 27.5 16C27.5 17.36 26.8 18.57 25.62 19.25L12.16 26.99C11.57 27.33 10.92 27.5 10.27 27.5ZM10.27 7C9.98 7 9.75 7.11 9.63 7.17C9.44 7.28 9 7.6 9 8.26V23.74C9 24.39 9.44 24.72 9.63 24.83C9.82 24.94 10.32 25.16 10.89 24.83L24.35 17.09C24.92 16.76 24.98 16.22 24.98 16C24.98 15.78 24.92 15.24 24.35 14.92L10.9 7.17C10.68 7.04 10.46 7 10.28 7H10.27Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
