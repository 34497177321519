import { SvgIcon, SvgIconProps } from './svg-icon';

export function SpeakerOnIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox={viewBox} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.745 27.5034C13.9667 27.5023 13.1886 27.2523 12.53 26.7734L4.03 20.5134C3.07 19.8134 2.5 18.6834 2.5 17.4934V14.5234C2.5 13.3334 3.07 12.2034 4.03 11.5034L12.53 5.23338C13.67 4.39338 15.18 4.26338 16.44 4.90338C17.71 5.54338 18.5 6.83338 18.5 8.25338V23.7434C18.5 25.1634 17.71 26.4534 16.44 27.0934C15.9015 27.3626 15.3233 27.5026 14.745 27.5034ZM14.745 27.5034L14.75 27.5034H14.74L14.745 27.5034ZM14.7452 7.00339C14.4869 7.00437 14.2386 7.08443 14.01 7.25338L5.51 13.5134C5.19 13.7534 5 14.1234 5 14.5234V17.4934C5 17.8934 5.19 18.2634 5.51 18.5034L14.01 24.7634C14.4 25.0434 14.89 25.0934 15.31 24.8734C15.73 24.6534 16 24.2434 16 23.7534V8.26338C16 7.78338 15.74 7.36338 15.31 7.14338C15.1316 7.05419 14.9336 7.00428 14.7452 7.00339ZM28.5 7.00336H26V25.0034H28.5V7.00336ZM21.5 11.0034H24V21.0034H21.5V11.0034Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
