import { getStyle } from './get-style';

const SCROLLABLE_OVERFLOWS = new Set(['auto', 'scroll', 'overlay']);

/**
 * Check if element is scrollable.
 */
export function isScrollable(element: Element, axis: 'x' | 'y' | 'xy' = 'xy') {
  const style = getStyle(element);
  if ((axis === 'xy' || axis === 'x') && SCROLLABLE_OVERFLOWS.has(style.overflowX)) {
    return true;
  }
  if ((axis === 'xy' || axis === 'y') && SCROLLABLE_OVERFLOWS.has(style.overflowY)) {
    return true;
  }
  return false;
}
