export type SlideData = {
  id: string;
  name: string;
  launchUrl: string;
  bgImageUrl: string;
  previewImageUrl: string;
  shortDescription: string;
  bgVideoUrl: string;
  logoImageUrl: string;
  infoUrl: string;
};

export enum SlideVideoState {
  NoSource = 'no-source',
  Loading = 'loading',
  Ready = 'ready',
  Playing = 'playing',
  Paused = 'paused',
  Ended = 'ended',
  Error = 'error',
}
