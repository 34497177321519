import { useRef, useState, useMemo } from 'react';

export function useRefState<T>(initialValue: T) {
  const [val, setVal] = useState<T>(initialValue);
  const stateValRef = useRef<T>(val);
  const dispatchValRef = useRef<T>(val);
  const dispatchRef = useRef<(v: T) => void>();
  const getValueRef = useRef<() => T>();

  // Keep stateValRef synced to the state on render time.
  stateValRef.current = val;

  // Create dispatch function only once.
  if (!dispatchRef.current) {
    dispatchRef.current = (newValue) => {
      // Keep dispatchValRef synced to the latest dispatch value.
      dispatchValRef.current = newValue;
      return setVal(newValue);
    };
  }

  // Create value getter only once.
  if (!getValueRef.current) {
    getValueRef.current = (useDispatchValue = false) => {
      return useDispatchValue ? dispatchValRef.current : stateValRef.current;
    };
  }

  return useMemo(() => {
    return [val, dispatchRef.current!, getValueRef.current!] as const;
  }, [val, setVal]);
}
