import { forwardRef, useMemo, memo } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import styles from './markdown.module.css';

export const Markdown = forwardRef<HTMLDivElement, Options>(function Markdown(
  { children, className = '', skipHtml = true, components, ...restProps },
  ref,
) {
  const componentsMemo: typeof components = useMemo(() => {
    return {
      h1: ({ node, className, ...props }) => (
        <h3 className={`${styles.h1} ${className}`} {...props} />
      ),
      h2: ({ node, className, ...props }) => (
        <h4 className={`${styles.h2} ${className}`} {...props} />
      ),
      h3: ({ node, className, ...props }) => (
        <h5 className={`${styles.h3} ${className}`} {...props} />
      ),
      h4: ({ node, className, ...props }) => (
        <h6 className={`${styles.h4} ${className}`} {...props} />
      ),
      h5: ({ node, className, ...props }) => (
        <h6 className={`${styles.h5} ${className}`} {...props} />
      ),
      h6: ({ node, className, ...props }) => (
        <h6 className={`${styles.h6} ${className}`} {...props} />
      ),
      ...components,
    };
  }, [components]);

  return (
    <div ref={ref} className={`${styles.root} ${className}`}>
      <ReactMarkdown skipHtml={skipHtml} components={componentsMemo} {...restProps}>
        {children}
      </ReactMarkdown>
    </div>
  );
});

export const MarkdownMemo = memo(Markdown);
