import { memo } from 'react';
import { TagPreviewMemo, TagPreviewProps } from '../tag-preview/tag-preview';
import styles from './featured-tags.module.css';

export type FeaturedTagsProps = {
  tags: TagPreviewProps[];
};

export function FeaturedTags({ tags }: FeaturedTagsProps) {
  // No tags, no render.
  if (!tags.length) return null;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {tags.map((tag, index) => (
          <TagPreviewMemo key={index} {...tag} />
        ))}
      </div>
    </div>
  );
}

export const FeaturedTagsMemo = memo(FeaturedTags);
