import { memo, useRef, useState, useEffect } from 'react';
import { useStore } from '../../providers/store-provider';
import styles from './app-card.module.css';

// This value should be approximately the popup's start delay + animation
// duration. The reason for this is that we want to start playing the video
// after the popup is fully animated and visible.
const PLAY_TIMEOUT_DURATION = 400;

export type AppCardVideoProps = {
  videoUrl: string;
  isCardActive: boolean;
  currentTimeRef: React.MutableRefObject<number>;
};

export function AppCardVideo({ videoUrl, isCardActive, currentTimeRef }: AppCardVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playTimeoutRef = useRef<number>(-1);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const debugDisableVideos = useStore(({ debug }) => debug.disableVideos);

  // Play video once it's ready.
  useEffect(() => {
    if (!isCardActive || debugDisableVideos !== false) return;

    const videoElem = videoRef.current;
    if (!videoElem) return;

    let didUnmount = false;

    const playVideo = () => {
      playTimeoutRef.current = window.setTimeout(() => {
        videoElem
          .play()
          .then(() => {
            if (didUnmount) return;
            setVideoPlaying(true);
          })
          .catch((e) => {
            if (didUnmount) return;
            console.error(e);
          });
      }, PLAY_TIMEOUT_DURATION);
    };

    // Play video if it's ready.
    if (videoElem.readyState === 4) {
      videoElem.currentTime = currentTimeRef.current;
      playVideo();
    }
    // Otherwise, let's wait for the video to be ready.
    else {
      videoElem.load();
      videoElem.currentTime = currentTimeRef.current;
      videoElem.addEventListener('canplaythrough', playVideo, { once: true });
    }

    return () => {
      const { currentTime, ended, paused } = videoElem;
      didUnmount = true;

      window.clearTimeout(playTimeoutRef.current);
      videoElem.removeEventListener('canplaythrough', playVideo);

      // Update currentTimeRef.
      currentTimeRef.current = ended ? 0 : currentTime;

      // Pause video if it's playing.
      if (!paused) {
        videoElem.pause();
        setVideoPlaying(false);
      }
    };
  }, [isCardActive, currentTimeRef, debugDisableVideos]);

  return videoUrl && !debugDisableVideos ? (
    <video
      ref={videoRef}
      className={`${styles.video} ${isVideoPlaying ? styles.isPlaying : ''}`}
      src={videoUrl}
      muted={true}
      aria-hidden="true"
      preload="none"
      onEnded={() => {
        setVideoPlaying(false);
      }}
    ></video>
  ) : null;
}

export const AppCardVideoMemo = memo(AppCardVideo);
