import { useSyncExternalStore } from 'react';

function getSnapshot() {
  return !document.hidden;
}

function getServerSnapshot() {
  return true;
}

function subscribe(callback: () => void) {
  document.addEventListener('visibilitychange', callback, false);
  return () => {
    document.removeEventListener('visibilitychange', callback, false);
  };
}

export function useIsPageVisible() {
  const isVisible = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  return isVisible;
}
