import { memo } from 'react';
import { AppCardMemo, AppCardProps } from '../app-card/app-card';
import { CardScrollerMemo } from '../card-scroller/card-scroller';
import { BulletDiamondIcon } from '../svg-icons/bullet-diamond';
import styles from './tag-preview.module.css';

export type TagPreviewProps = {
  id: string;
  name: string;
  apps: AppCardProps[];
};

export function TagPreview({ id, name, apps }: TagPreviewProps) {
  // No apps, no render.
  if (apps.length === 0) return null;

  return (
    <div className={styles.root} role="region" aria-labelledby={`${styles.root}-${id}`}>
      <div className={styles.heading}>
        <h2 id={`${styles.root}-${id}`} className={styles.headingTitle}>
          <BulletDiamondIcon />
          {name}
        </h2>
      </div>
      <CardScrollerMemo className={styles.cardList}>
        {apps.map((app, index) => (
          <AppCardMemo
            key={index}
            id={app.id}
            name={app.name}
            launchUrl={app.launchUrl}
            previewImageUrl={app.previewImageUrl}
            previewVideoUrl={app.previewVideoUrl}
            shortDescription={app.shortDescription}
            tags={app.tags}
            isUnityGame={app.isUnityGame}
          />
        ))}
      </CardScrollerMemo>
    </div>
  );
}

export const TagPreviewMemo = memo(TagPreview);
